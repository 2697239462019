// src/components/Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../assets/images/logo-stardust.jpg'; // Replace with the actual path to your logo image
import logo from '../assets/images/logo_venexchange.png'; // Replace with the actual path to your logo image
import { CartContext } from '../contexts/CartContext'; // Import the CartContext

const Header = () => {
  const { cart } = useContext(CartContext); // Use the CartContext to get the cart items

  return (
    <header style={styles.header}>
      <Link to="/">
        <img src={logo} alt="Logo" style={styles.logo} />
      </Link>
      <nav style={styles.nav}>
        <Link to="/" style={styles.navLink}>Inicio</Link>
        <Link to="/cart" style={styles.navLink}>
          Carrito ({cart.length}) {/* Display the number of items in the cart */}
        </Link>
      </nav>
      {/* Add additional navigation or other header content here */}
    </header>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensure space between logo and navigation
    padding: '10px 20px',
    backgroundColor: '#fff', // Change this to your desired background color
  },
  logo: {
    height: '90px', // Adjust the height as needed
    width: 'auto',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    marginLeft: '20px',
    textDecoration: 'none',
    color: '#000', // Change this to your desired text color
    fontSize: '16px', // Adjust the font size as needed
  },
};

export default Header;

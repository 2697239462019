// src/components/OrderConfirmation.js
import React from 'react';

const OrderConfirmation = () => {
    return (
        <div className="order-confirmation">
            <h1>Thank you for your order!</h1>
            <p>Your order has been placed successfully.</p>
            <a href="/">Continue Shopping</a>
        </div>
    );
};

export default OrderConfirmation;

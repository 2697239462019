// src/components/Checkout.js
import React, { useContext, useState } from 'react';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
    const { cart, clearCart } = useContext(CartContext);
    const [customerInfo, setCustomerInfo] = useState({ name: '', address: '', email: '', paymentMethod: '' });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo({ ...customerInfo, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle order submission, e.g., save to database, process payment
        console.log('Order submitted:', { cart, customerInfo });

        // For now, clear the cart and navigate to a confirmation page or home
        clearCart();
        navigate('/order-confirmation');
    };

    if (cart.length === 0) {
        return <div>Your cart is empty. <a href="/">Go back to shopping</a></div>;
    }

    return (
        <div className="checkout">
            <h1>Checkout</h1>
            <form onSubmit={handleSubmit}>
                <h2>Shipping Information</h2>
                <input
                    type="text"
                    name="name"
                    value={customerInfo.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                />
                <input
                    type="text"
                    name="address"
                    value={customerInfo.address}
                    onChange={handleInputChange}
                    placeholder="Address"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={customerInfo.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                />

                <h2>Payment Information</h2>
                <select
                    name="paymentMethod"
                    value={customerInfo.paymentMethod}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select Payment Method</option>
                    <option value="creditCard">Credit Card</option>
                    <option value="paypal">PayPal</option>
                    <option value="bankTransfer">Bank Transfer</option>
                </select>

                <h2>Order Summary</h2>
                <ul>
                    {cart.map((item, index) => (
                        <li key={index}>
                            {item.name} - ${item.price}
                        </li>
                    ))}
                </ul>
                <p>Total: ${cart.reduce((total, item) => total + item.price, 0)}</p>

                <button type="submit">Place Order</button>
            </form>
        </div>
    );
};

export default Checkout;

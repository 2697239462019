import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import { Link, useNavigate } from 'react-router-dom';

const Cart = () => {
    const { cart, removeFromCart, clearCart } = useContext(CartContext);
    const navigate = useNavigate(); // Initialize the navigate function

    if (cart.length === 0) {
        return <div>Your cart is empty. <Link to="/">Go back to shopping</Link></div>;
    }

    const handleCheckout = () => {
        navigate('/checkout'); // Navigate to the checkout page
    };

    return (
        <div className="cart">
            <h1>Your Cart</h1>
            <ul>
                {cart.map((item, index) => (
                    <li key={index}>
                        <img src={item.images[0]?.image} alt={item.name} />
                        <h2>{item.name}</h2>
                        <p>${item.price}</p>
                        <button onClick={() => removeFromCart(item.id)}>Remove</button>
                    </li>
                ))}
            </ul>
            <button onClick={clearCart}>Clear Cart</button>
            <button onClick={handleCheckout}>Proceed to Checkout</button> {/* Updated to handle navigation */}
        </div>
    );
};

export default Cart;
